import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { Typography } from '@mui/material';
import AddUser from './AddUser';
import FilterUsers from './FilterUsers';
import { PrimaryButton } from 'lib/components/Buttons/buttons';
import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';

import Search from 'lib/components/Search/Search';
import { useUser } from 'hooks/useUser';
// import { ReactComponent as DetailsArrow } from 'resources/svg/menu-navbar/detailsArrow.svg';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { CustomTable, PageLayout } from 'lib';
import Tooltip from '../../lib/components/Tooltip/TooltipWrapper';
import { Chip } from '@mui/material';
import { Helmet } from 'react-helmet';
import { UsersAPI } from 'api_darex';

const Users = () => {
    const { t } = useTranslation();
    const navigate = useHistory();
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [openFilter, setOpenFilter] = useState(false);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const { user, can } = useUser();
    const [triggerRefetch, setTriggerRefetch] = useState(false);
    const [triggerFilter, setTriggerFilter] = useState(false);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [filterData, setFilterData] = useState({
        brandId: user.roleId === '3' ? user.UserBrand[0].brandId : '',
        workingPoint: '',
        roleId: user.roleId === '4' || user.roleId === '3' ? '4' : '',
    });
    const [isFilter, setIsFilter] = useState(false);

    const resetSearch = () => {
        setIsSearch(false);
        setSearch('');
    };

    const resetFilter = () => {
        setIsFilter(false);
        setFilterData({
            brandId: user.roleId === '3' ? user.UserBrand[0].brandId : '',
            workingPoint: '',
            roleId: user.roleId === '4' || user.roleId === '3' ? '4' : '',
        });
    };

    useEffect(() => {
        if (!can(3)) {
            navigate.goBack();
        }
    }, []);

    const labels = [
        {
            id: 'fullName',
            label: t('Name'),
            minWidth: 10,
        },
        {
            id: 'email',
            label: 'Email',
            minWidth: 10,
        },
        {
            id: 'roles',
            label: t('Role'),
            minWidth: 10,
        },
        {
            id: 'UserBrand',
            label: t('Brands'),
            minWidth: 10,
        },
        {
            id: 'createdAt',
            label: t('Creation date'),
            minWidth: 10,
        },
        {
            id: 'actions',
            minWidth: 10,
        },
    ];

    const cellModifier = (row, column, value) => {
        if (column.id === 'fullName') {
            return (
                <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                    <Typography variant="tableContent">
                        {row.name} {row.firstName}
                    </Typography>
                    {user.id === row.id && <Chip label={t('ME')} variant="outlined" color="primary" size="small" />}
                </div>
            );
        }
        if (column.id === 'UserBrand') {
            const display = value.map((item, index) => {
                if (index === value.length - 1) {
                    return item.brand.name;
                } else {
                    return item.brand.name + ', ';
                }
            });
            return <Typography variant="tableContent">{display}</Typography>;
        }
        if (column.id === 'roles') {
            return <Typography variant="tableContent">{value.name}</Typography>;
        }
        if (column.id === 'createdAt') {
            return <div style={{ display: 'flex' }}>{dayjs(value).format('DD MMM YYYY')}</div>;
        }
        if (column.id === 'actions') {
            return (
                can(3) &&
                user.roleId !== '4' && (
                    <Tooltip position="left" textTip={<text>{t('View more details')}</text>} followCursor>
                        <div className="details-arrow-style" onClick={() => navigate.push(`/users/${row.id}`)}>
                            <ChevronRightIcon />
                        </div>
                    </Tooltip>
                )
            );
        }
        return <Typography variant="tableContent">{value}</Typography>;
    };

    const getDynamicData = () => {
        if (user.roleId === '1' || user.roleId === '2' || user.roleId >= '5') {
            return { func: UsersAPI.get, additionalId: null, filterSearch: null, filterFilter: null };
        } else if (user.roleId === '3' || user.roleId === '4') {
            return {
                func: UsersAPI.getByBrandsId,
                additionalId: user.UserBrand.map((item) => item.brandId),
                filterSearch: (data) =>
                    data.filter(
                        (item) =>
                            item.roleId === '4' &&
                            item.UserBrand.some((brand) =>
                                user.UserBrand.map((uBrand) => uBrand.brandId).includes(brand.brandId)
                            )
                    ),
                filterFilter: (data) =>
                    data.filter(
                        (item) =>
                            item.roleId === '4' &&
                            item.UserBrand.some((brand) =>
                                user.UserBrand.map((uBrand) => uBrand.brandId).includes(brand.brandId)
                            )
                    ),
            };
        }
    };

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {user.roleId === '3' ? t('Councilors') : t('Users')}</title>
            </Helmet>
            <PageLayout
                title={user.roleId === '3' ? t('Councilors') : t('Users')}
                subTitle={user.roleId === '3' ? t('Manage your counsellors') : t('Manage your users')}
                actionArea={
                    <>
                        <Search
                            isFilter={isFilter}
                            isSearch={isSearch}
                            setIsSearch={setIsSearch}
                            search={search}
                            setSearch={setSearch}
                            nameToFind={user.roleId === '3' ? t('Search counselor') : t('Search user')}
                            triggerRefetch={triggerRefetch}
                            setTriggerRefetch={setTriggerRefetch}
                            triggerSearch={triggerSearch}
                            setTriggerSearch={setTriggerSearch}
                            setLoading={setLoading}
                            setterFunction={setUsers}
                            setIsFilter={setIsFilter}
                            resetFilter={resetFilter}
                        />
                        <PrimaryButton startIcon={<FilterListIcon />} color="light" onClick={() => setOpenFilter(true)}>
                            {user.roleId === '3' ? t('FILTER COUNCILORS') : t('FILTER USERS')}
                        </PrimaryButton>
                        {can(2) && (
                            <PrimaryButton startIcon={<AddIcon />} color="primary" onClick={() => setOpen(true)}>
                                {t('ADD USER')}
                            </PrimaryButton>
                        )}
                    </>
                }
            >
                <CustomTable
                    title={user.roleId === '3' ? t('Councilors') : t('Users')}
                    labels={labels}
                    loading={loading}
                    setLoading={setLoading}
                    tableData={users}
                    cellModifier={cellModifier}
                    getterFunction={getDynamicData().func}
                    additionalId={getDynamicData().additionalId}
                    setterFunction={setUsers}
                    withPagination={true}
                    triggerRefetch={triggerRefetch}
                    triggerSearch={triggerSearch}
                    searchFunction={UsersAPI.searchUsers}
                    search={search}
                    isSearch={isSearch}
                    filterSearch={getDynamicData().filterSearch}
                    triggerFilter={triggerFilter}
                    setTriggerFilter={setTriggerFilter}
                    isFilter={isFilter}
                    filterFilter={getDynamicData().filterFilter}
                    filterFunction={UsersAPI.filterUsers}
                    filterData={filterData}
                />
            </PageLayout>
            <AddUser
                open={open}
                setOpen={setOpen}
                triggerRefetch={triggerRefetch}
                setTriggerRefetch={setTriggerRefetch}
            />
            <FilterUsers
                open={openFilter}
                setOpen={setOpenFilter}
                filterData={filterData}
                setFilterData={setFilterData}
                triggerFilter={triggerFilter}
                setTriggerFilter={setTriggerFilter}
                triggerRefetch={triggerRefetch}
                setTriggerRefetch={setTriggerRefetch}
                setIsFilter={setIsFilter}
                setLoading={setLoading}
                setData={setUsers}
                resetSearch={resetSearch}
                resetFilter={resetFilter}
                isFilter={isFilter}
            />
        </>
    );
};

export default Users;
