import React, { useState, useEffect } from 'react';
import styles from './AddClient.module.scss';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { Formik, Form } from 'formik';
import { emailRegex, phoneRegex } from 'utils/regex';
import * as yup from 'yup';
import { Select, TextField } from 'lib';
import { Grid, Typography, MenuItem } from '@mui/material';
import { ClientSourceAPI } from 'api_darex';
import { UsersAPI, ClientsAPI, CitiesAPI } from 'api_darex';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useUser } from 'hooks/useUser';

const IndividualClientForm = ({
    client,
    formikRef,
    clientDataForCreation,
    submitCallback,
    createFromOffer,
    setModalOpen,
}) => {
    const { user } = useUser();
    const { t } = useTranslation();
    const { vw } = useWindowDimensions();

    const history = useHistory();

    const [clientSources, setClientSources] = useState([]);
    const [users, setUsers] = useState([]);
    const [cities, setCities] = useState([]);
    const [states, setStates] = useState([]);

    useEffect(() => {
        ClientSourceAPI.get().then((res) => setClientSources(res.data));
        UsersAPI.get().then((res) => setUsers(res.data.content.filter((user) => user.roleId === '4')));
        CitiesAPI.getAllCities().then((res) => setCities(res.data));
        CitiesAPI.getAllStates().then((res) => setStates(res.data));
    }, []);

    /**
     * All brands from user
     */
    const brands = user.UserBrand.map((b) => b.brand);

    /**
     * If editing, use client's brands
     * Else, use all the possible brands from currently logged user
     */
    const initialValues = {
        id: null,
        ...client,
        // userId:
        userId:
            client.type === '' || Object.keys(clientDataForCreation).length > 0 || client.fromClientsCreation
                ? user.id
                : client.user.id,
    };

    const validationSchema = yup.object().shape({
        // Cheile trebuie sa aiba aceasi denumire ca in INITIAL_FORM_STATE
        firstName: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(3, t('First name must contain at least three characters'))
            .required(t('First name is mandatory')),
        lastName: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(3, t('Last name must contain at least three characters'))
            .required(t('Last name is mandatory')),

        email: yup
            .string()
            .trim()
            .matches(emailRegex, t('Email you introduced is not valid'))
            .required(t('Email is mandatory')),

        phone: yup
            .string()
            .trim()
            .matches(phoneRegex, t('The phone number you introduced is not a valid one'))
            .required(t('Phone number is mandatory')),
        stateId: yup.string().required(t('Choose the county')),
        cityId: yup.string().required(t('Choose the city')),
        street: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The name of the street must contain at least three characters')),

        nrStreet: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The street number must contain at least one number')),

        apartmentBuilding: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The buildings name must contain at least one character')),

        staircase: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The entrance name must contain at least three characters')),

        floor: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The floors number must contain at least one character')),

        apartmentNr: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(1, t('The number of the appartment must contain at least one character')),

        postalCode: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(6, t('The zip code must contain at least six characters')),

        clientSourceId: yup.string().required(t('Choose the source')),

        brandId: yup.string().required(t('Choose the brand')),
        userId: yup.string().required(t('Choose the counselor')),
        details: yup
            .string()
            .typeError(t('This name is not a valid one!'))
            .trim()
            .min(3, t('This field must contain at least three characters')),
    });

    const handleSubmit = () => {
        const { values: obj } = formikRef.current;

        obj.type = 'Individual';
        obj.brandId = obj.brandId.toString();
        obj.nrStreet = Number(obj.nrStreet);
        obj.apartmentNr = Number(obj.apartmentNr);
        delete obj.id;
        delete obj.CUI;
        delete obj.firmName;
        delete obj.Tasks;
        delete obj.brand;
        delete obj.city;
        delete obj.clientSource;
        delete obj.state;
        delete obj.user;
        delete obj.status;

        if (client.id && !createFromOffer)
            ClientsAPI.update(client.id, obj).then((res) => {
                if (res.ok === true) {
                    toast.success(t('The client has been updated successfully!'));
                    history.push('/clients');
                } else {
                    toast.error(t('An error ocurred. Please try again'));
                }
            });
        else {
            if (Object.keys(clientDataForCreation).length > 0 || client.fromClientsCreation) {
                ClientsAPI.createClientOnAnotherBrand(
                    createFromOffer ? client.id : client.idForClient,
                    obj.brandId,
                    user.id
                ).then((res) => {
                    if (res.ok === true) {
                        toast.success(t('The client has been added successfully!'));
                        submitCallback(res.data);

                        !createFromOffer && history.push('/clients');
                        createFromOffer && setModalOpen(false);
                    } else {
                        if (
                            res.error.status === 404 &&
                            res.error.data.message === 'The Client on Brand already exist!'
                        ) {
                            toast.error(t('This client is already your client on your brand/brands!'));
                        } else if (res.error.status === 500) {
                            toast.error(t('An error ocurred. Please try again'));
                        }
                    }
                });
            } else {
                ClientsAPI.create(obj).then((res) => {
                    if (res.ok === true) {
                        toast.success(t('The client has been added successfully!'));
                        submitCallback(res.data);
                        !createFromOffer && history.push('/clients');
                        createFromOffer && setModalOpen(false);
                    } else {
                        if (res.error.status === 400 && res.error.data.message === 'This email already exist') {
                            toast.error(t('A client with this email address already exist!'));
                        } else if (res.error.status === 500) {
                            toast.error(t('An error ocurred. Please try again'));
                        }
                    }
                });
            }
        }
    };

    return (
        <div className={styles.addNewClient}>
            <Formik
                innerRef={formikRef}
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {(props) => (
                    <Form>
                        <Grid
                            container
                            spacing={2}
                            justifyContent={'space-between'}
                            direction={vw < 670 ? 'column' : 'row'}
                            sx={{ marginBottom: '16px' }}
                        >
                            <Grid item xs={4}>
                                <TextField name="firstName" label={t('First name*')} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField name="lastName" label={t('Last name*')} />{' '}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField name="phone" label={t('Phone number*')} />{' '}
                            </Grid>
                            <Grid sx={{ marginBottom: '24px' }} item xs={4}>
                                <TextField name="email" label={t('Email*')} />{' '}
                            </Grid>
                        </Grid>
                        <Typography variant={'clientData'}> {t('Address')} </Typography>
                        <Grid
                            sx={{ marginTop: '8px', marginBottom: '16px' }}
                            container
                            spacing={2}
                            justifyContent={'space-between'}
                            direction={vw < 670 ? 'column' : 'row'}
                        >
                            <Grid item xs={4}>
                                <Select sx name="stateId" label={t('County*')}>
                                    {states.map((county) => (
                                        <MenuItem key={county.id} value={county.id}>
                                            {county.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item xs={4}>
                                <Select
                                    name="cityId"
                                    label={t('City*')}
                                    disabled={props.values.county !== '' ? false : true}
                                >
                                    {cities
                                        .filter((city) => city.state_id === props.values.stateId)
                                        .map((city) => (
                                            <MenuItem key={city.id} value={city.id}>
                                                {city.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <TextField name="street" label={t('Street')} />
                            </Grid>
                        </Grid>
                        <Grid
                            sx={{ marginBottom: '32px' }}
                            container
                            justifyContent={'space-between'}
                            spacing={2}
                            direction={vw < 670 ? 'column' : 'row'}
                        >
                            <Grid item xs={2}>
                                <TextField name="nrStreet" label={t('Street number')} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField name="apartmentBuilding" label={t('Building')} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField name="staircase" label={t('Scară')} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField name="floor" label={t('Floor')} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField name="apartmentNr" label={t('Apartment number')} />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField name="postalCode" label={t('Zip Code')} />
                            </Grid>
                        </Grid>

                        <Grid
                            sx={{ marginBottom: '32px' }}
                            container
                            justifyContent={'space-between'}
                            spacing={2}
                            direction={vw < 670 ? 'column' : 'row'}
                        >
                            <Grid item xs={4}>
                                <div style={{ marginBottom: '16px' }}>
                                    <Typography variant={'clientData'}> {t('Client Source')} </Typography>
                                </div>

                                <Select name="clientSourceId">
                                    {clientSources.map((source) => (
                                        <MenuItem key={source.id} value={source.id}>
                                            {source.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ marginBottom: '16px' }}>
                                    <Typography variant={'clientData'}> {t('Counselor')} </Typography>
                                </div>

                                <Select
                                    disabled={
                                        user.roles.name === 'Consilier' || user.roles.name === 'Asistent de vanzari'
                                    }
                                    name="userId"
                                >
                                    {users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            {user.name + ' ' + user.firstName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ marginBottom: '16px' }}>
                                    <Typography variant={'clientData'}> {t('Brand')} </Typography>{' '}
                                </div>
                                <Select name="brandId">
                                    {brands.map((brand) => (
                                        <MenuItem key={brand.id} value={brand.id} sx>
                                            {brand.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>

                        <Typography variant="clientData"> {t('Client Details')} </Typography>
                        <Grid container justifyContent={'space-between'} spacing={2}>
                            <Grid item xs={12}>
                                <div style={{ marginTop: '16px' }}>
                                    <TextField name="details" placeholder={t('Hobbies, expectations...')} />
                                </div>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

IndividualClientForm.propTypes = {
    client: PropTypes.object,

    // Required
    formikRef: PropTypes.object.isRequired,
};

IndividualClientForm.defaultProps = {
    client: {},
};

export default IndividualClientForm;
