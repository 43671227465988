import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EditUser from './EditUser';
import ConfirmModal from 'lib/components/Modals/ConfirmModal';

import { Typography, Divider } from '@mui/material';
import { NavigationButton } from 'lib/components/Buttons/buttons';
import { SmallGeneralAdministrationButton } from 'lib/components/Buttons/buttons';
import SecurityIcon from '@mui/icons-material/Security';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';

import { PageLayout } from 'lib';

import { ReactComponent as ObjectivesIcon } from 'resources/svg/users/ObjectivesIcon.svg';
import { ReactComponent as OffersIcon } from 'resources/svg/users/OffersIcon.svg';
import { ReactComponent as TasksIcon } from 'resources/svg/users/TasksIcon.svg';
import { ReactComponent as ClientsIcon } from 'resources/svg/users/ClientsIcon.svg';
import { UsersAPI } from 'api_darex';
import { LoadingSpinner } from 'lib';
import ChangePasswordHard from 'pages/Profile/ChangePasswordHard';
import { useUser } from 'hooks/useUser';
import { Helmet } from 'react-helmet';
import DefaultUserPicture from 'resources/img/user/DefaultUserPicture.png';
import { useCallback } from 'react';
import useWindowDimensions from 'hooks/useWindowDimensions';

const User = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const [open, setOpen] = useState(false);
    const [openBlock, setOpenBlock] = useState(false);
    const [openReset, setOpenReset] = useState(false);
    const [userState, setUserState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [profilePic, setProfilePic] = useState(null);
    const { user, can } = useUser();

    const getUserById = useCallback(() => {
        UsersAPI.getById(id).then((res) => {
            setUserState(res.data);
            setLoading(false);
        });
    }, [id]);

    useEffect(() => {
        getUserById();
    }, [getUserById]);

    useEffect(() => {
        (async () => {
            const imgId = userState?.ProfilePicture[userState.ProfilePicture.length - 1]?.file.fileName;
            if (imgId) {
                const { data: imgUrl } = await UsersAPI.getPicture(imgId);
                setProfilePic(imgUrl);
            }
        })();
    }, [userState]);

    const displayCarBrand = () => {
        const display = userState.UserBrand.map((item, index) => {
            if (index === userState.UserBrand.length - 1) {
                return item.brand.name;
            } else {
                return item.brand.name + ', ';
            }
        });
        return <>{display}</>;
    };

    const handleBlockUnBlock = () => {
        if (userState.isBlocked) {
            UsersAPI.unblock(id).then((res) => {
                if (res.ok) {
                    getUserById();
                    setOpenBlock(false);
                    toast.success(t('This user has been unblocked!'));
                } else {
                    toast.error(t('Something went wrong! This user could not be unblocked!'));
                }
            });
        } else {
            UsersAPI.block(id).then((res) => {
                if (res.ok) {
                    getUserById();
                    setOpenBlock(false);
                    toast.success(t('This user has been blocked!'));
                } else {
                    toast.error(t('Something went wrong! This user could not be blocked!'));
                }
            });
        }
    };

    return (
        <>
            <Helmet>
                <title>Darex CRM :: {user.roleId === '3' ? t('Counselor') : t('User')}</title>
            </Helmet>
            <PageLayout
                title={`${user.roleId === '3' ? t('Counselor') : t('User')}: ${
                    userState?.firstName ? userState.firstName : ''
                }`}
                backArrow={true}
            >
                {loading ? (
                    <LoadingSpinner loading={loading} margin="20% auto" />
                ) : (
                    <div className="mid-wrapper">
                        <div className="mid-one">
                            <div className="one-top">
                                <div className="image-containerUser">
                                    <div className="profile-avatar">
                                        <img
                                            src={profilePic ? profilePic : DefaultUserPicture}
                                            loading="lazy"
                                            alt="user"
                                        />
                                    </div>
                                    {/* )} */}
                                    {(can(2) || user.id === userState.id) && (
                                        <NavigationButton
                                            variant="text"
                                            color="blue"
                                            startIcon={<EditIcon />}
                                            onClick={() => setOpen(true)}
                                        >
                                            {t('EDIT')}
                                        </NavigationButton>
                                    )}
                                </div>
                                <div className="info-detailsUsers">
                                    <Typography variant="userHeader">
                                        {userState.name}
                                        <br />
                                        {userState.firstName}
                                    </Typography>

                                    {userState.UserBrand.length > 0 && (
                                        <div className="one-left-brands">
                                            <Typography variant="userPrimaryData">
                                                {t('Brands')}: {displayCarBrand()}
                                            </Typography>
                                        </div>
                                    )}
                                    {userState.workingPoint && (
                                        <Typography variant="userSecondaryData">
                                            {userState.workingPoint.name}
                                        </Typography>
                                    )}
                                    <Divider darklight style={{ width: '40%' }} />
                                    <Typography variant="userSecondaryData">{userState.roles.name}</Typography>
                                    <Typography variant="userPrimaryData" sx={{ overflowWrap: 'break-word' }}>
                                        {userState.email}
                                    </Typography>
                                </div>
                            </div>
                            {can(2) && (
                                <div className="one-bottom">
                                    {user.roleId === '1' && user.id !== Number(id) && (
                                        <NavigationButton
                                            variant="text"
                                            color={!userState.isBlocked ? 'red' : 'green'}
                                            startIcon={!userState.isBlocked ? <LockIcon /> : <LockOpenIcon />}
                                            onClick={() => setOpenBlock(true)}
                                            style={{ padding: '1rem' }}
                                        >
                                            {userState.isBlocked ? t('UNBLOCK') : t('BLOCK')}
                                        </NavigationButton>
                                    )}

                                    {/* {user.roleId === '1' && (
                                        <>
                                            <NavigationButton
                                                variant="text"
                                                startIcon={<SecurityIcon />}
                                                onClick={() => setOpenReset(true)}
                                            >
                                                {t('RESET PASSWORD')}
                                            </NavigationButton>
                                        </>
                                    )} */}
                                </div>
                            )}
                        </div>
                        {userState.roleId === '4' && (
                            <div className="mid-two">
                                <div className="bottom-wrapper">
                                    {can(6) && (
                                        <SmallGeneralAdministrationButton
                                            text={t('Offers')}
                                            icon={<OffersIcon />}
                                            onClick={() => history.push(`/users/offers/${id}`)}
                                        />
                                    )}
                                    {can(9) && (
                                        <SmallGeneralAdministrationButton
                                            text={t('Tasks')}
                                            icon={<TasksIcon />}
                                            onClick={() => history.push(`/users/tasks/${id}`)}
                                        />
                                    )}
                                    {can(12) && (
                                        <SmallGeneralAdministrationButton
                                            text={t('Objectives')}
                                            icon={<ObjectivesIcon />}
                                            onClick={() => history.push(`/users/objectives/${id}`)}
                                        />
                                    )}
                                    {can(15) && (
                                        <SmallGeneralAdministrationButton
                                            text={t('Clients')}
                                            icon={<ClientsIcon />}
                                            onClick={() => history.push(`/users/clients/${id}`)}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </PageLayout>
            <EditUser open={open} setOpen={setOpen} userState={userState} getUserById={getUserById} />
            <ChangePasswordHard open={openReset} setOpen={setOpenReset} userData={userState} />
            <ConfirmModal
                open={openBlock}
                setOpen={setOpenBlock}
                text={
                    !loading && userState.isBlocked
                        ? t('Are you sure you want to unblock this user?')
                        : t('Are you sure you want to block this user?')
                }
                onClickButtonYes={handleBlockUnBlock}
            />
        </>
    );
};

export default User;
