import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomDialog } from 'lib';

import { UsersAPI } from 'api_darex';

import DaciaObjectives from './DaciaObjectives';
import RenaultObjectives from './RenaultObjectives';
import NissanObjectives from './NissanObjectives';

const AddObjectivesModal = ({
    open = false,
    setOpen = () => {},
    tabName,
    rowData = null,
    triggerRefetch,
    setTriggerRefetch,
    filteredAdviser = [],
}) => {
    const { t } = useTranslation();
    const [users, setUsers] = useState([]);

    useEffect(() => {
        UsersAPI.get().then((res) => setUsers(res.data.content));
    }, []);

    const formRefDacia = useRef();

    const handleSubmitDacia = () => {
        if (formRefDacia.current) {
            formRefDacia.current.handleSubmit();
        }
    };

    return (
        <div>
            <CustomDialog
                open={open}
                handleClose={() => setOpen(false)}
                title={t('Add objectives')}
                onClickButtonFinish={handleSubmitDacia}
                buttonClose={t('Cancel')}
                buttonFinish={t('Finish')}
                width="600px"
            >
                <Box
                    sx={{
                        // p: 2,
                        backgroundColor: 'white',
                        borderRadius: '1.5rem',
                        height: '100%',
                    }}
                >
                    {tabName === 'DACIA' && (
                        <DaciaObjectives
                            formRefDacia={formRefDacia}
                            users={filteredAdviser}
                            setOpen={setOpen}
                            rowData={rowData}
                            triggerRefetch={triggerRefetch}
                            setTriggerRefetch={setTriggerRefetch}
                        />
                    )}
                    {tabName === 'RENAULT' && (
                        <RenaultObjectives
                            formRefDacia={formRefDacia}
                            users={filteredAdviser}
                            setOpen={setOpen}
                            rowData={rowData}
                            triggerRefetch={triggerRefetch}
                            setTriggerRefetch={setTriggerRefetch}
                        />
                    )}
                    {tabName === 'NISSAN' && (
                        <NissanObjectives
                            formRefDacia={formRefDacia}
                            users={filteredAdviser}
                            setOpen={setOpen}
                            rowData={rowData}
                            triggerRefetch={triggerRefetch}
                            setTriggerRefetch={setTriggerRefetch}
                        />
                    )}
                </Box>
            </CustomDialog>
        </div>
    );
};

export default AddObjectivesModal;
